import request from "../request";
import { stringify } from "qs";

/**
 * 示例
 * @param payload
 * @returns {*}
 */
export function getUserInfo(payload) {
    return request.get({
        url: `/auth/user/login?${stringify(payload)}`,
        params:{

        }
    })
}

/**
 * 查询保单信息
 * @param payload
 * @returns {*}
 */
export function getListInsuranceDetails(payload) {
    return request.post({
        url: `/listInsuranceDetails?${stringify(payload)}`,
        params:{
        }
    })
}

/**
 * ocr阿里身份证信息校验查询
 * @param formData
 * @returns {*}
 */
export function uploadOcrIdentify(formData) {
    return request.post({
        url: `/ocrIdentify`,
        data: formData, // 确保文件数据通过 data 传递
        headers: {
            'Content-Type': 'multipart/form-data' // 指定内容类型
        }
    })
}

/**
 * 查询保单号
 * @param payload
 * @returns {*}
 */
export function getRenewalOfInsuranceSearch(payload) {
    return request.get({
        url: `/renewalOfInsuranceSearch?${stringify(payload)}`,
        params:{
        }
    })
}

/**
 * 新增保单信息
 * @param payload
 * @returns {*}
 */
export function addInsuranceApplicantApi(payload) {
    return request.post({
        url: `/addInsuranceApplicant`,
        headers: {
            'Content-Type': 'application/json',
        },
        data:{...payload},
    })
}

/**
 * 新增签名信息
 * @param payload
 * @returns {*}
 */
export function addUploadUserSign(payload) {
    return request.post({
        url: `/uploadSign`,
        data:{...payload}, // 确保文件数据通过 data 传递
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
/**
 * 查询订单信息
 * @param payload
 * @returns {*}
 */
export function getOrderDetails(payload) {
    return request.get({
        url: `/insurance/order/details?${stringify(payload)}`,
    })
}

/**
 * 查询订单信息
 * @param payload
 * @returns {*}
 */
export function goPayOrder(payload) {
    return request.post({
        url: `/insurance/order/createPayOrder?${stringify(payload)}`,
    })
}
/**
 * 查询订单信息
 * @param payload
 * @returns {*}
 */
export function getPayOrderInfo(payload) {
    return request.post({
        url: `/insurance/order/getPayInfo?${stringify(payload)}`,
    })
}

/**
 * 获取sdk基础信息
 * @param payload
 * @returns {*}
 */
export function getSdkConfig(payload) {
    return request.post({
        url: `/insurance/order/getJsSdkConfig?${stringify(payload)}`,
        params:{

        }
    })
}
