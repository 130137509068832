import { Navigate } from "react-router-dom";
import { lazy } from "react";

const Home = lazy(() => import("@/views/Home/index"));
const RechargePage = lazy(() => import("@/views/recharge/index"));
const OrderDetail = lazy(() => import("@/views/OrderDetail/index"));

const routes = [
	{
		path: "/",
		element: <Navigate to="/home" />,
	},
	{
		path: "/home",
		element: <Home />,
	},
	{
		path: "/recharge",
		element: <RechargePage />,
	},{
		path: "/orderDetail",
		element: <OrderDetail />,
	},
];

export default routes;
